import React from 'react'
import "./Navbar.css"
import { NavLink } from "react-router-dom"
export default function Navbar() {
    const [click, setClick] =React.useState(false);

    const handleClick = () => setClick(!click);
    const Close = () => setClick(false);
  return (
    <div>
    <div className={click ? "main-container" : ""}  onClick={()=>Close()} />
     <nav className="navbar" onClick={e => e.stopPropagation()}>
       <div className="nav-container">
         <NavLink act to="/" className="nav-logo">
           Ameet Parekh
          
         </NavLink>
         <ul className={click ? "nav-menu active" : "nav-menu"}>
           <li className="nav-item">
             <NavLink
               
               to="/"
               activeClassName="active"
               className="nav-links"
               onClick={click ? handleClick : null}
             >
               Home
             </NavLink>
           </li>
           <li className="nav-item">
             <NavLink
               act
               to="/about"
               activeClassName="active"
               className="nav-links"
               onClick={click ? handleClick : null}
             >
               About
             </NavLink>
           </li>
           <li className="nav-item">
             <NavLink
               act
               to="/blog"
               activeClassName="active"
               className="nav-links"
               onClick={click ? handleClick : null}
             >
               Blog
             </NavLink>
           </li>
           <li className="nav-item">
             <NavLink
               act
               to="/blog"
               activeClassName="active"
               className="nav-links"
               onClick={click ? handleClick : null}
             >
               Programs
             </NavLink>
           </li>
           <li className="nav-item">
             <NavLink
               act
               to="/contact"
               activeClassName="active"
               className="nav-links"
              onClick={click ? handleClick : null}
             >
               Contact Us
             </NavLink>
           </li>
         </ul>
         <div className="nav-icon" onClick={handleClick}>
          {
            click ?
            (
              <img src='./assets/icons8-close.svg' alt=''/>

            ):
            (
              <img src='./assets/icons8-menu.svg' alt=''/>

            )
          }
         </div>
       </div>
     </nav>
   </ div>
  )
}
