import React from 'react'
import Marquee from "react-fast-marquee";
import Footer from '../Footer/Footer';
import Navbar from '../Navbar/Navbar';
import { NewSlider } from '../NewSlider/NewSlider';
import Slider from '../Slider/Slider';
import "./Home.css"
import Fade from 'react-reveal/Fade';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
export default function Home() {
    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 1
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 1
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 1
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      }
    const slides = [
        {
          city: 'Paris',
          country: 'France',
          img: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/142996/paris.jpg',
        },
        {
          city: 'Singapore',
          img: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/142996/singapore.jpg',
        },
        {
          city: 'Prague',
          country: 'Czech Republic',
          img: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/142996/prague.jpg',
        },
        {
          city: 'Amsterdam',
          country: 'Netherlands',
          img: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/142996/amsterdam.jpg',
        },
        {
          city: 'Moscow',
          country: 'Russia',
          img: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/142996/moscow.jpg',
        },
      ];
  return (
    <div className='home'>

        <Navbar/>
        <NewSlider slides={slides}/>
        <Marquee className='marquee' speed={100}>
        <img src='https://e7.pngegg.com/pngimages/833/991/png-clipart-logo-forbes-industry-magazine-black-cover-company-text.png' alt=''/>
        <img src='https://e7.pngegg.com/pngimages/833/991/png-clipart-logo-forbes-industry-magazine-black-cover-company-text.png' alt=''/>
        <img src='https://e7.pngegg.com/pngimages/833/991/png-clipart-logo-forbes-industry-magazine-black-cover-company-text.png' alt=''/>
        <img src='https://e7.pngegg.com/pngimages/833/991/png-clipart-logo-forbes-industry-magazine-black-cover-company-text.png' alt=''/>
        <img src='https://e7.pngegg.com/pngimages/833/991/png-clipart-logo-forbes-industry-magazine-black-cover-company-text.png' alt=''/>        <img src='https://e7.pngegg.com/pngimages/833/991/png-clipart-logo-forbes-industry-magazine-black-cover-company-text.png' alt=''/>
      </Marquee>
      <div>
            <div  className='what-do-we-do-title'>WHAT DO WE DO</div>
         
            <div className='what-do-we-do'>
            <Fade bottom>
                <div>
                <img src='https://st2.depositphotos.com/1051392/5967/i/950/depositphotos_59675283-stock-photo-3d-man-what-we-do.jpg' alt=''/>
       
                </div>
                <div>
                <img src='https://st2.depositphotos.com/1051392/5967/i/950/depositphotos_59675283-stock-photo-3d-man-what-we-do.jpg' alt=''/>
       
                </div>
                <div>
                <img src='https://st2.depositphotos.com/1051392/5967/i/950/depositphotos_59675283-stock-photo-3d-man-what-we-do.jpg' alt=''/>
       
                </div>
                </Fade>

            </div>
            <div className='what-do-we-do below'>
            <Fade bottom>
                <div>
                <img src='https://st2.depositphotos.com/1051392/5967/i/950/depositphotos_59675283-stock-photo-3d-man-what-we-do.jpg' alt=''/>
       
                </div>
                <div>
                <img src='https://st2.depositphotos.com/1051392/5967/i/950/depositphotos_59675283-stock-photo-3d-man-what-we-do.jpg' alt=''/>
       
                </div>
                <div>
                <img src='https://st2.depositphotos.com/1051392/5967/i/950/depositphotos_59675283-stock-photo-3d-man-what-we-do.jpg' alt=''/>
       
                </div>
                </Fade>

            </div>
          
        </div>
        <div>
            <div className='our-story-title'>
                Our Story
            </div>

          
        
 
        <div className='our-story-container'>
        <Fade bottom>
            <div  className='our-story-left'>
            <img src='https://rapgp.org/sites/default/files/default_images/intervenant.jpg' alt=''/>
       
            </div>
            <div  className='our-story-right'>
                <div   className='our-story-right-heading'>
                    HEADING
                </div>
                <div   className='our-story-right-text'>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec imperdiet risus et sagittis finibus. Morbi vehicula ligula molestie aliquam auctor. Donec convallis, nibh id pretium ultricies, urna est pellentesque lectus, eu varius dolor est sit amet lectus. Nunc commodo est posuere bibendum consequat. Etiam sit amet tristique leo. Sed nec ligula.
                </div>
                <div className='our-story-right-btn'>
                    Read More...

                </div>

            </div>
            </Fade>
        </div>
       
        </div>
      
        <div>
<div className='world-social'>
  <img width="100%" className='world-social-img' height="100%" src='https://static.vecteezy.com/system/resources/previews/002/142/369/original/world-map-of-circles-with-the-usa-highlighted-vector.jpg' alt=''/>
  <div className='posters-body'>
    <div id="posts">

   
    
    <div className='each-poster'>
      <div>
        Followers
      </div> 
      <div>
        Twitter
      </div>
      <div>
        <img src='./assets/icons8-twitter.svg' alt='' />
      </div>
    </div>
    <div className='each-poster'>
      <div>
        Followers
      </div> 
      <div>
        Twitter
      </div>
      <div>
        <img src='./assets/icons8-twitter.svg' alt='' />
      </div>
    </div>
    <div className='each-poster'>
      <div>
        Followers
      </div> 
      <div>
        Twitter
      </div>
      <div>
        <img src='./assets/icons8-twitter.svg' alt='' />
      </div>
    </div>
    <div className='each-poster'>
      <div>
        Followers
      </div> 
      <div>
        Twitter
      </div>
      <div>
        <img src='./assets/icons8-twitter.svg' alt='' />
      </div>
    </div>
    <div className='each-poster'>
      <div>
        Followers
      </div> 
      <div>
        Twitter
      </div>
      <div>
        <img src='./assets/icons8-twitter.svg' alt='' />
      </div>
    </div>
    <div className='each-poster'>
      <div>
        Followers
      </div> 
      <div>
        Twitter
      </div>
      <div>
        <img src='./assets/icons8-twitter.svg' alt='' />
      </div>
    </div>
    
    <div className='each-poster'>
      <div>
        Followers
      </div> 
      <div>
        Twitter
      </div>
      <div>
        <img src='./assets/icons8-twitter.svg' alt='' />
      </div>
    </div>
    
    <div className='each-poster'>
      <div>
        Followers
      </div> 
      <div>
        Twitter
      </div>
      <div>
        <img src='./assets/icons8-twitter.svg' alt='' />
      </div>
    </div>
   
    
    
    </div>
    
  
  </div>
</div>

        </div>

        <div>
            <div className='read-watch-listen'>
                <div className='read-watch-listen-title'>
READ . WATCH . LISTEN
                </div>

                <Fade bottom>
                <div className='read-watch-listen-body'>
                    <div>
                        <div>
                        <img src='https://image.shutterstock.com/image-photo/read-word-made-colourful-wooden-260nw-1802305426.jpg' alt=''/>
       
                        </div>
                        <div>
                        BOOK
                        </div>
                    </div>
                    <div>
                        <div>
                        <img src='https://image.shutterstock.com/image-illustration/watch-word-600w-336444281.jpg' alt=''/>
       
                        </div>
                        <div>
                        WATCH
                        </div>
                    </div>
                    <div>
                        <div>
                        <img src='https://cdn1.vectorstock.com/i/1000x1000/11/90/graffiti-listen-word-sprayed-in-black-over-white-vector-28191190.jpg' alt=''/>
       
                        </div>
                        <div>
                        LISTEN
                        </div>
                    </div>

                </div></Fade>
            </div>
        </div>
        <div>
          <img width="100%" style={{marginTop:80}} src='https://petapixel.com/assets/uploads/2022/02/best-photography-quotes-by-photographers.jpg' alt=''/>
        </div>
        <div>
            <div className='events'>
                UPCOMING EVENTS
            </div>
            <Fade bottom>
        <Carousel responsive={responsive}>
  <div className='new-events-box'>
    <div>
    <img src='https://cdn1.vectorstock.com/i/1000x1000/11/90/graffiti-listen-word-sprayed-in-black-over-white-vector-28191190.jpg' alt=''/>
    </div>
    <div>
    <div  className='new-events-box-text'>
       ------------------------------------------------------------------------------------------------------------
    </div>
    <div  className='new-events-box-btn'>
        Register
    </div>
    </div>
 
       </div>
       
       
       <div className='new-events-box'>
    <div>
    <img src='https://cdn1.vectorstock.com/i/1000x1000/11/90/graffiti-listen-word-sprayed-in-black-over-white-vector-28191190.jpg' alt=''/>
    </div>
    <div>
    <div  className='new-events-box-text'>
       ------------------------------------------------------------------------------------------------------------
    </div>
    <div  className='new-events-box-btn'>
        Register
    </div>
    </div>
 
       </div>
       <div className='new-events-box'>
    <div>
    <img src='https://cdn1.vectorstock.com/i/1000x1000/11/90/graffiti-listen-word-sprayed-in-black-over-white-vector-28191190.jpg' alt=''/>
    </div>
    <div>
    <div  className='new-events-box-text'>
       ------------------------------------------------------------------------------------------------------------
    </div>
    <div  className='new-events-box-btn'>
        Register
    </div>
    </div>
 
       </div>
       <div className='new-events-box'>
    <div>
    <img src='https://cdn1.vectorstock.com/i/1000x1000/11/90/graffiti-listen-word-sprayed-in-black-over-white-vector-28191190.jpg' alt=''/>
    </div>
    <div>
    <div  className='new-events-box-text'>
       ------------------------------------------------------------------------------------------------------------
    </div>
    <div  className='new-events-box-btn'>
        Register
    </div>
    </div>
 
       </div>
       <div className='new-events-box'>
    <div>
    <img src='https://cdn1.vectorstock.com/i/1000x1000/11/90/graffiti-listen-word-sprayed-in-black-over-white-vector-28191190.jpg' alt=''/>
    </div>
    <div>
    <div  className='new-events-box-text'>
       ------------------------------------------------------------------------------------------------------------
    </div>
    <div  className='new-events-box-btn'>
        Register
    </div>
    </div>
 
       </div>
       <div className='new-events-box'>
    <div>
    <img src='https://cdn1.vectorstock.com/i/1000x1000/11/90/graffiti-listen-word-sprayed-in-black-over-white-vector-28191190.jpg' alt=''/>
    </div>
    <div>
    <div  className='new-events-box-text'>
       ------------------------------------------------------------------------------------------------------------
    </div>
    <div  className='new-events-box-btn'>
        Register
    </div>
    </div>
 
       </div>
       
</Carousel>
</Fade>
        </div>
        
      

        <div>
            <Footer/>
        </div>
      
    </div>
  )
}
