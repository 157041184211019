import React from 'react'
import Footer from '../Footer/Footer';
import Navbar from '../Navbar/Navbar';
import Fade from 'react-reveal/Fade';
import { NewSlider } from '../NewSlider/NewSlider';
import "./About.css"
import Marquee from "react-fast-marquee";
export default function About() {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  }
  const slides = [
    {
      city: 'Paris',
      country: 'France',
      img: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/142996/paris.jpg',
    },
    {
      city: 'Singapore',
      img: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/142996/singapore.jpg',
    },
    {
      city: 'Prague',
      country: 'Czech Republic',
      img: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/142996/prague.jpg',
    },
    {
      city: 'Amsterdam',
      country: 'Netherlands',
      img: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/142996/amsterdam.jpg',
    },
    {
      city: 'Moscow',
      country: 'Russia',
      img: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/142996/moscow.jpg',
    },
  ];
  return (
    <div>
         <Navbar/>
        <NewSlider slides={slides}/>

        <Marquee className='marquee' speed={100}>
        <img src='https://e7.pngegg.com/pngimages/833/991/png-clipart-logo-forbes-industry-magazine-black-cover-company-text.png' alt=''/>
        <img src='https://e7.pngegg.com/pngimages/833/991/png-clipart-logo-forbes-industry-magazine-black-cover-company-text.png' alt=''/>
        <img src='https://e7.pngegg.com/pngimages/833/991/png-clipart-logo-forbes-industry-magazine-black-cover-company-text.png' alt=''/>
        <img src='https://e7.pngegg.com/pngimages/833/991/png-clipart-logo-forbes-industry-magazine-black-cover-company-text.png' alt=''/>
        <img src='https://e7.pngegg.com/pngimages/833/991/png-clipart-logo-forbes-industry-magazine-black-cover-company-text.png' alt=''/>        <img src='https://e7.pngegg.com/pngimages/833/991/png-clipart-logo-forbes-industry-magazine-black-cover-company-text.png' alt=''/>
      </Marquee>
          
        <div className='our-story-container'>
        <Fade bottom>
        <div  className='our-story-right'>
                <div   className='our-story-right-heading'>
                    HEADING
                </div>
                <div   className='our-story-right-text'>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec imperdiet risus et sagittis finibus. Morbi vehicula ligula molestie aliquam auctor. Donec convallis, nibh id pretium ultricies, urna est pellentesque lectus, eu varius dolor est sit amet lectus. Nunc commodo est posuere bibendum consequat. Etiam sit amet tristique leo. Sed nec ligula.
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec imperdiet risus et sagittis finibus. Morbi vehicula ligula molestie aliquam auctor. Donec convallis, nibh id pretium ultricies, urna est pellentesque lectus, eu varius dolor est sit amet lectus. Nunc commodo est posuere bibendum consequat. Etiam sit amet tristique leo. Sed nec ligula.
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec imperdiet risus et sagittis finibus. Morbi vehicula ligula molestie aliquam auctor. Donec convallis, nibh id pretium ultricies, urna est pellentesque lectus, eu varius dolor est sit amet lectus. Nunc commodo est posuere bibendum consequat. Etiam sit amet tristique leo. Sed nec ligula.
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec imperdiet risus et sagittis finibus. Morbi vehicula ligula molestie aliquam auctor. Donec convallis, nibh id pretium ultricies, urna est pellentesque lectus, eu varius dolor est sit amet lectus. Nunc commodo est posuere bibendum consequat. Etiam sit amet tristique leo. Sed nec ligula.
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec imperdiet risus et sagittis finibus. Morbi vehicula ligula molestie aliquam auctor. Donec convallis, nibh id pretium ultricies, urna est pellentesque lectus, eu varius dolor est sit amet lectus. Nunc commodo est posuere bibendum consequat. Etiam sit amet tristique leo. Sed nec ligula.
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec imperdiet risus et sagittis finibus. Morbi vehicula ligula molestie aliquam auctor. Donec convallis, nibh id pretium ultricies, urna est pellentesque lectus, eu varius dolor est sit amet lectus. Nunc commodo est posuere bibendum consequat. Etiam sit amet tristique leo. Sed nec ligula.
               
                </div>
                

            </div>
            <div  className='our-story-left'>
            <img src='https://cdn3.vectorstock.com/i/1000x1000/31/67/business-meeting-with-people-discussing-vector-17713167.jpg' alt=''/>

            </div>
           
            </Fade>
        </div>
 
       





    
        <div className='our-story-container2 space-above'>
        <Fade bottom>
        <div  className='our-story-left2'>
            <img src='https://cdn3.vectorstock.com/i/1000x1000/31/67/business-meeting-with-people-discussing-vector-17713167.jpg' alt=''/>

            </div>
        <div  className='our-story-right2'>
                <div   className='our-story-right-heading'>
                    HEADING
                </div>
                <div   className='our-story-right-text2'>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec imperdiet risus et sagittis finibus. Morbi vehicula ligula molestie aliquam auctor. Donec convallis, nibh id pretium ultricies, urna est pellentesque lectus, eu varius dolor est sit amet lectus. Nunc commodo est posuere bibendum consequat. Etiam sit amet tristique leo. Sed nec ligula.
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec imperdiet risus et sagittis finibus. Morbi vehicula ligula molestie aliquam auctor. Donec convallis, nibh id pretium ultricies, urna est pellentesque lectus, eu varius dolor est sit amet lectus. Nunc commodo est posuere bibendum consequat. Etiam sit amet tristique leo. Sed nec ligula.
              
                </div>
                

            </div>
          
           
            </Fade>
        </div>












            
        <div className='our-story-container2 reverse '>
        <Fade bottom>
        <div  className='our-story-right2'>
                <div   className='our-story-right-heading'>
                    HEADING
                </div>
                <div   className='our-story-right-text2'>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec imperdiet risus et sagittis finibus. Morbi vehicula ligula molestie aliquam auctor. Donec convallis, nibh id pretium ultricies, urna est pellentesque lectus, eu varius dolor est sit amet lectus. Nunc commodo est posuere bibendum consequat. Etiam sit amet tristique leo. Sed nec ligula.
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec imperdiet risus et sagittis finibus. Morbi vehicula ligula molestie aliquam auctor. Donec convallis, nibh id pretium ultricies, urna est pellentesque lectus, eu varius dolor est sit amet lectus. Nunc commodo est posuere bibendum consequat. Etiam sit amet tristique leo. Sed nec ligula.
               
                </div>
                

            </div>
            <div  className='our-story-left2'>
            <img src='https://cdn3.vectorstock.com/i/1000x1000/31/67/business-meeting-with-people-discussing-vector-17713167.jpg' alt=''/>

            </div>
           
            </Fade>
        </div>













            
        <div className='our-story-container2 '>
        <Fade bottom>
        <div  className='our-story-left2'>
            <img src='https://cdn3.vectorstock.com/i/1000x1000/31/67/business-meeting-with-people-discussing-vector-17713167.jpg' alt=''/>

            </div>
        <div  className='our-story-right2'>
                <div   className='our-story-right-heading'>
                    HEADING
                </div>
                <div   className='our-story-right-text2'>
               Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec imperdiet risus et sagittis finibus. Morbi vehicula ligula molestie aliquam auctor. Donec convallis, nibh id pretium ultricies, urna est pellentesque lectus, eu varius dolor est sit amet lectus. Nunc commodo est posuere bibendum consequat. Etiam sit amet tristique leo. Sed nec ligula.
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec imperdiet risus et sagittis finibus. Morbi vehicula ligula molestie aliquam auctor. Donec convallis, nibh id pretium ultricies, urna est pellentesque lectus, eu varius dolor est sit amet lectus. Nunc commodo est posuere bibendum consequat. Etiam sit amet tristique leo. Sed nec ligula.
               
                </div>
                

            </div>
       
           
            </Fade>
        </div>













        <div className='grids'>
        <div className='each-grid'>
          <div>
          <div>
           <img src='https://rapgp.org/sites/default/files/default_images/intervenant.jpg' alt=''/>

           </div>
            <div className='each-grid-title'>
              
            </div>
            <div className='each-grid-text'>
              --------------------------------------------------------
            </div>
            <div className='each-grid-btn'>
              Explore
            </div>

          </div>
       
        </div>
        <div className='each-grid'>
          <div>
          <div>
           <img src='https://rapgp.org/sites/default/files/default_images/intervenant.jpg' alt=''/>

           </div>
            <div className='each-grid-title'>
             
            </div>
            <div className='each-grid-text'>
              --------------------------------------------------------
            </div>
            <div className='each-grid-btn'>
              Explore
            </div>

          </div>
      
        </div>
        <div className='each-grid'>
          <div>
          <div>
           <img src='https://rapgp.org/sites/default/files/default_images/intervenant.jpg' alt=''/>

           </div>
            <div className='each-grid-title'>
             
            </div>
            <div className='each-grid-text'>
              --------------------------------------------------------
            </div>
            <div className='each-grid-btn'>
              Explore
            </div>

          </div>
       
        </div>
       </div>

       <div>
          <img width="100%" style={{position:'relative',zIndex:'10000',marginTop:150}} src='https://petapixel.com/assets/uploads/2022/02/best-photography-quotes-by-photographers.jpg' alt=''/>
        </div>

        <Footer/>
    </div>
  )
}
