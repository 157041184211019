import React from 'react'
import "./Footer.css"

export default function Footer() {
  return (
    <div  className='footer' >
        <div>
            <div>
                <img src='https://seeklogo.com/images/B/business-company-logo-C561B48365-seeklogo.com.png' alt=''/>
            </div>
            <div>
COMPANY NAME
            </div>
            <div>
ADDRESS
            </div>

        </div>
        <div>
            <div>
                < a href=''>
                    Link 1
                </a>
            </div>
            <div>
                < a href=''>
                    Link 2
                </a>
            </div>
            <div>
                < a href=''>
                    Link 3
                </a>
            </div>
            <div>
                < a href=''>
                    Link 4
                </a>
            </div>
        </div>
        <div>
            <div>
                < a href=''>
                    Link 1
                </a>
            </div>
            <div>
                < a href=''>
                    Link 2
                </a>
            </div>
            <div>
                < a href=''>
                    Link 3
                </a>
            </div>
            <div>
                < a href=''>
                    Link 4
                </a>
            </div>
        </div>
        <div>

        </div>
        <div >
          
        </div>
    </div>
  )
}
